const getMemberSchools = require('./api/get-member-schools');
const filters = require('./filters.js');

const FOUNDER_TYPE = 'fondateur';
const STATUS_PUBLISHED = 'publish';
const DEFAULT_SCHOOL_DOMAIN_COLOR = '#8c9696';
const MARKERS = {};
const SCHOOL_STATES = [];
let map;

/**
 * Fetch regions delimitations.
 *
 * @returns {Promise<any>}
 */
function fetchRegionDelimitations() {
    return new Promise((resolve, reject) => {
        $.getJSON("/wp-content/plugins/member-schools-map/assets/json/france-regions.json",function(data){
            resolve(data);
        });
    })
}

/**
 * Simplify property access.
 *
 * @param memberSchool
 * @returns {{id: *, teachingArea: null, secondary: boolean, type: null, taxUrl: boolean, visibleByDomain: boolean, visibleByProfile: boolean}}
 */
function simplifyPropertyAccess(memberSchool) {
    return {
        id: memberSchool.id,
        teachingArea: memberSchool.school_teaching_area && memberSchool.school_teaching_area.length ? memberSchool.school_teaching_area[0].teaching_area_name : null,
        secondary: "1" === memberSchool.site_secondaire,
        type: memberSchool.school_type && memberSchool.school_type.length ? memberSchool.school_type[0] : null,
        tool13: !!memberSchool.school_tax_url,
        visibleByDomain: true,
        visibleByProfile: true,
        visible: true
    };
}

/**
 * Fetch member schools.
 *
 * @returns {*}
 */
function fetchMemberSchools() {
    return getMemberSchools()
        .then(memberSchools => {
            memberSchools.forEach(memberSchool => {
                if(memberSchool.status !== STATUS_PUBLISHED) {
                    return;
                }

                const simplifiedPropertyAccess = simplifyPropertyAccess(memberSchool);
                if(simplifiedPropertyAccess.type.toLowerCase() === FOUNDER_TYPE) {
                    return;
                }

                SCHOOL_STATES.push(simplifiedPropertyAccess);
            });

            return memberSchools;
        });
}

/**
 * Create marker.
 *
 * @param school
 */
function createMarker(school) {
    const schoolDomainColor = school.school_teaching_area ? school.school_teaching_area[0].teaching_area_color : DEFAULT_SCHOOL_DOMAIN_COLOR;
    const schoolDomainName = school.school_teaching_area ? school.school_teaching_area[0].teaching_area_name : undefined;
    const notSetField = 'Non renseigné';

    const icon = L.divIcon({
        className: "my-custom-pin",
        iconAnchor: [12, 28],
        iconSize: [24, 28],
        popupAnchor: [0, -24],
        html: `<svg viewBox="0 0 149 178" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="${schoolDomainColor}" stroke="#FFF" stroke-width="6" stroke-miterlimit="10" d="M114.33,20.01l-4.64,-4.641c-9.958,-8.31 -22.612,-12.711 -35.577,-12.374c-14.943,-0.104 -29.264,6.073 -39.443,17.015c-10.864,10.234 -17.025,24.518 -17.015,39.443c0,37.241 50.271,111.567 50.271,111.567c4.641,4.64 8.508,4.64 12.375,-0c-0,-0 51.044,-75.872 51.044,-111.567c-0,-15.468 -6.187,-28.616 -17.015,-39.443Z"/><circle cx="74" cy="59" r="20" fill="#fff" /></svg>`
    });

    const marker = L.marker(
        [school.school_latitude, school.school_longitude],
        {
            icon: icon,
            title: school.school_name || notSetField,
            alt: school.school_name || notSetField
        })
        .bindPopup(getPopupHtml(
            school.school_name,
            school.school_logo.guid,
            school.school_city,
            school.school_contact,
            school.school_contact_email,
            school.school_website,
            school.school_apprenticeship,
            school.school_tax_url,
            schoolDomainColor,
            schoolDomainName,
            school.id
        ));
    MARKERS[school.id] = marker;

    return marker;
}

/**
 *
 * Get popup html.
 *
 * @param name
 * @param logoUrl
 * @param city
 * @param contactName
 * @param contactMail
 * @param website
 * @param apprenticeship
 * @param taxUrl
 * @param schoolDomainColor
 * @param schoolDomainName
 * @param id
 * @returns {string}
 */
function getPopupHtml(name, logoUrl, city, contactName, contactMail, website, apprenticeship, taxUrl, schoolDomainColor, schoolDomainName, id) {
    let html = '';
    html += '<address>';
    html += '  <div class="line">';
    html += '    <span class="left"><img src="' + logoUrl + '" alt="logo" class="school-logo" /></span>';
    html += '    <span class="right">';
    html += '      <strong>' + name + '</strong>';
    if(schoolDomainName) {
        html += '      <span class="data" style="color: ' + schoolDomainColor + ';">';
        html += '        <span class="data--label"><span class="glyphicon glyphicon-star"></span> Domaine </span>';
        html += '        <span class="data--data">' + schoolDomainName + '</span>';
        html += '      </span>';
    }
    html += '      <span class="data">';
    html += '        <span class="data--label"><span class="glyphicon glyphicon-map-marker"></span> Ville </span>';
    html += '        <span class="data--data">' + city + '</span>';
    html += '      </span>';
    if(contactName || contactMail) {
        html += '      <span class="data">';
        html += '        <span class="data--label"> <span class="glyphicon glyphicon-user"></span> Email contact </span>';
        html += '        <span class="data--data">';
    }
    if(contactMail) {
        html += ' <a href="mailto:' + contactMail + '">';
    }
    if(contactName) {
        html += contactName;
    }
    else {
        html += "email";
    }
    if(contactMail) {
        html += '</a>';
    }
    if(contactName || contactMail) {
        html += '        </span>';
        html += '      </span>';
    }
    if(website) {
        html += '<span class="data">';
        html += '  <span class="data--label"><span class="glyphicon glyphicon-link"></span> Site web</span>';
        html += '  <span class="data--data"><a href="' + website + '" target="_blank" rel="noreferrer noopener">Visiter le site</a></span>'
        html += '</span>';
    }
    html += '      <span class="data"><span class="data--label"><span class="glyphicon glyphicon-book"></span> Apprentissage </span><span class="data--data">' + (!apprenticeship ? "non" : "oui") + '</span></span>';
    if(taxUrl) {
        html += ' <span class="data"><span class="data--label"><span class="glyphicon glyphicon-euro"></span> Taxe d’apprentissage </span><span class="data--data"><a href="' + taxUrl + '" target="_blank" rel="noreferrer noopener">Verser vos 13%</a></span></span>';
    }
    html += '    </span>';
    html += '  </div>';
    html += '</address>';

    return html;
}

/**
 * Init.
 */
function init() {
    if(document.getElementById('map')){
        Promise.all([ fetchRegionDelimitations(), fetchMemberSchools() ])
            .then(results => {
            const [ regionDelimitations, memberSchools ] = results;

            // initialize the map on the "map" div with a given center and zoom
            map = L.map('map').setView([46.6594088, 1.9293621], 6);

            L.tileLayer('https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png', {
                maxZoom: 20,
                attribution: '&copy; Openstreetmap France | &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            })
            .addTo(map);

            // add GeoJSON layer to the map once the file is loaded
            L.geoJson(regionDelimitations, {
                onEachFeature: function( feature, layer ){
                    layer.bindPopup( "<strong>" + feature.properties.nom + "</strong><br/><a href=\"/ecoles-par-region/?region=" + feature.properties.slug + "\">Voir la liste des établissements de la région</a>" )
                }
            }).addTo(map);

            memberSchools.forEach(school => {
                if(!school.school_latitude || !school.school_longitude) {
                    return;
                }

                if(!school) {
                    return;
                }

                const marker = createMarker(school);
                marker.addTo(map);
            });

            filters.initFilterEvents(SCHOOL_STATES, MARKERS, map);
            filters.initTools13FilterOnLoad(SCHOOL_STATES, MARKERS, map);
        });
    }
}

init();