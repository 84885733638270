const axios = require("axios").default;

/**
 * Fetch nb pages.
 *
 * @returns {Promise<AxiosResponse<T>>}
 */

 async function fetchNbPages() {
    return await axios.get(`/wp-json/wp/v2/school?per_page=100&page=1`)
        .then(response => response.headers['x-wp-totalpages']);
}

module.exports = async () => {
    const nbPages = await fetchNbPages();

    const promises = [];
    for (let i = 1; i <= nbPages; i++) {
        let promise = axios.get(`/wp-json/wp/v2/school?per_page=100&page=${i}`)
            .then(response => {
                return response.data;
            });

        promises.push(promise);
    }

    return Promise.all(promises)
        .then(results => {
            let schools = [];

            for(let i in results) {
                const schoolsChunk = results[i];

                schools.push(...schoolsChunk);
            }

            return schools;
        });
}