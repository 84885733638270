const TOOLS_13_QUERY_STRING_PARAM_NAME = 'tools-13';
let unselectedAll = false;

/**
 * Is tool 13.
 *
 * @param memberSchool
 * @returns {*}
 */
function isTools13(memberSchool) {
    return memberSchool.tool13;
}

/**
 * Should tools 13 be checked.
 *
 * @returns {boolean}
 */
function shouldTools13BeChecked() {
    const urlParams = new URLSearchParams(window.location.search);

    return urlParams.get(TOOLS_13_QUERY_STRING_PARAM_NAME) === '1';
}

/**
 * Handle tools 13 checked. Display only schools that has tools 13 percent.
 */
function handleTools13Checked(schoolStates, markers, map) {
    schoolStates.forEach(schoolState => {
        if(isTools13(schoolState)) {
            schoolState.visibleByTax = true;

            if(!schoolState.visible && schoolState.visibleByDomain) {
                const marker = markers[schoolState.id];
                if (!marker) {
                    return;
                }
                marker.addTo(map);

                schoolState.visible = true;
            }

            return;
        }

        schoolState.visibleByTax = false;

        if(schoolState.visible) {
            const marker = markers[schoolState.id];
            if (!marker) {
                return;
            }
            map.removeLayer(marker);

            schoolState.visible = false;
        }
    });
}

/**
 * Handle tools 13 unchecked. Display every schools.
 */
function handleTools13Unchecked(schoolStates, markers, map) {
    schoolStates.forEach(schoolState => {
        schoolState.visibleByTax = true;

        if(!schoolState.visible && schoolState.visibleByDomain) {
            const marker = markers[schoolState.id];
            if(!marker) {
                return;
            }
            marker.addTo(map);

            schoolState.visible = true;
        }
    });
}

/**
 * Init tools 13 filter on load.
 */
function initTools13FilterOnLoad(schoolStates, markers, map) {
    if(shouldTools13BeChecked()) {
        document.querySelector('.tools-13-percent').checked = true;

        handleTools13Checked(schoolStates, markers, map);

        return;
    }

    // tools 13%
    const isChecked = document.querySelector('.tools-13-percent').checked;

    if(isChecked) {
        handleTools13Checked(schoolStates, markers, map);
    } else {
        handleTools13Unchecked(schoolStates, markers, map);
    }
}

/**
 * Init filter events.
 */
function initFilterEvents(schoolStates, markers, map) {
    // domain
    const domainCheckboxes = document.querySelectorAll('.domain');
    for(let domainCheckbox of domainCheckboxes) {
        domainCheckbox.addEventListener('change', e => {
            const isChecked = e.target.checked;
            const domain = e.target.getAttribute('data-domain-to-display');

            if(isChecked) {
                schoolStates.forEach(schoolState => {
                    if(!schoolState.teachingArea) {
                        return;
                    }

                    if(schoolState.teachingArea === domain) {
                        schoolState.visibleByDomain = true;

                        if(!schoolState.visible && schoolState.visibleByTax) {
                            const marker = markers[schoolState.id];
                            marker.addTo(map);
                            schoolState.visible = true;
                        }
                    }
                });
            } else {
                schoolStates.forEach(schoolState => {
                    if(!schoolState.teachingArea) {
                        return;
                    }

                    if(schoolState.teachingArea === domain) {
                        schoolState.visibleByDomain = false;

                        if(schoolState.visible) {
                            const marker = markers[schoolState.id];
                            map.removeLayer(marker);

                            schoolState.visible = false;
                        }
                    }
                });
            }
        });
    }

    // tools 13%
    document.querySelector('.tools-13-percent').addEventListener('change', e => {
        const isChecked = e.target.checked;
        
        if(isChecked) {
            handleTools13Checked(schoolStates, markers, map);
        } else {
            handleTools13Unchecked(schoolStates, markers, map);
        }
    });

    const selectUnselectAll = document.getElementById('select-unselect-all');
    selectUnselectAll.addEventListener('click', function(e) {
        e.preventDefault();
        if(unselectedAll) {
            // we display
            schoolStates.forEach(schoolState => {
                schoolState.visibleByDomain = true;
                if(schoolState.visibleByTax) {
                    const marker = markers[schoolState.id];
                    if(!marker) {
                        return;
                    }
                    marker.addTo(map);

                    schoolState.visible = true
                }
            });

            unselectedAll = false;

            for(let domainCheckbox of domainCheckboxes) {
                domainCheckbox.checked = true;
            }
            return;
        }

        // we hide
        schoolStates.forEach(schoolState => {
            if(schoolState.visible) {
                const marker = markers[schoolState.id];
                if(!marker) {
                    return;
                }
                map.removeLayer(marker);
            }

            schoolState.visibleByDomain = false;
            schoolState.visible = false;
        });

        unselectedAll = true;

        for(let domainCheckbox of domainCheckboxes) {
            domainCheckbox.checked = false;
        }
    });

}

module.exports = {
    initFilterEvents,
    initTools13FilterOnLoad
};